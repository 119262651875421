import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'app',
    component: () => import('../App.vue'),
    children: [
        {
            path: '',
            name: 'home',
            redirect: 'wyt',
            component: () => import('../views/home2'),
            children: [
              {
                path: 'wyt',
                name: 'wyt',
                component: () => import('../views/home2/wyt.vue')
              },
              {
                path: 'profile',
                name: 'profile',
                component: () => import('../views/home2/profile.vue')
              },
              {
                path: 'service',
                name: 'service',
                component: () => import('../views/home2/service.vue')
              },
              {
                path: 'link',
                name: 'link',
                component: () => import('../views/home2/link.vue')
              },
              {
                path: 'prod',
                name: 'prod',
                component: () => import('../views/home2/prod.vue')
              },
              {
                path: 'shop',
                name: 'shop',
                component: () => import('../views/home2/shop.vue')
              }
            ]
        },
        {
            path: 'yzdz',
            name: 'yzdz',
            meta: { title: '一针断痔' },
            component: () => import('../yzdz-mobile.vue')
        }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
